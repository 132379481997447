@import "../../styles/variables";

.spin-wrap {
	position: absolute;
	display: block;
	height: 32px;
	width: 32px;
}

.button {
	position: relative;
	letter-spacing: normal;
	line-height: 10px;
	font-weight: 600;
	box-sizing: border-box;
	cursor: pointer;
	text-decoration: none;
	flex: 0 0 auto;
	box-shadow: none;
	border-radius: 4px;
	text-align: center;
	min-width: 0;
	transition: opacity 0.5s;
	transition-delay: 0.5s;
	border: solid 1px transparent;
	&_blue, &_blue:hover {
		background-color: $color-blue;
		color: $color-white;
		border-color: $color-blue;
	}
	&_red, &_red:hover {
		background-color: $color-red;
		color: $color-white;
		border-color: $color-red;
	}

	&_transparent-blue, &_transparent-blue:hover {
		background-color: $color-transparent;
		color: $color-blue;
		border-color: $color-blue;
	}

	&_medium {
		padding: 10px 12px;
		text-transform: none;
		font-size: 12px;
	}

	&:disabled {
		opacity: 0.33;
	}
}
