@import "../../styles/variables";

.input-container {
	position: relative;
	height: 44px;
}

.placeholder {
	position: absolute;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.1s ease-in-out;
	left: 24px;
	white-space: nowrap;
	max-width: calc(100% - 48px) !important;
	text-overflow: ellipsis;
	overflow: hidden;
	border-bottom: 1px solid transparent;
	z-index: 2;
	color: rgba(33, 33, 33, 0.5);
	user-select: none;
	cursor: text;
	top: 12px;
	display: flex;

	&:first-letter {
		text-transform: capitalize !important;
	}
	&:before {
		display: block;
		left: -2px;
		right: -2px;
		position: absolute;
		top: 2px;
		height: 12px;
		background-color: #fff;
		z-index: -1;
	}

	&_focus {
		font-size: 12px;
		line-height: 1.2;
		color: rgba(33, 33, 33, 0.5);
		padding: 0 4px;
		top: -10px;
		left: 22px;

		&:before {
			content: '';
		}
	}
	&_disabled {
		color: #dcdcdc;
		cursor: default;
	}
	&_invalid {
		color: $color-red;
	}
}

.custom-input {
	border-radius: 5px;
	border: solid 1px #dcdcdc;
	color: #212121;
	font-size: 16px;
	font-family: lato, -apple-system, Roboto, BlinkMacSystemFont, serif;
	min-width: 10px;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	line-height: 1.4;
	font-weight: 700;
	-webkit-appearance: none;
	height: 44px;
	background-color: transparent;
	animation: onAutoFillCancel 5000s linear;
	position: relative;
	padding: 10px 9px 10px 24px;
	transition: 0.5s;

	&_invalid {
		border: solid 1px $color-red;
	}

	&_lighten {
		color: #4d4d4d;
	}

	&_with-icon {
		padding: 10px 28px 10px 24px;
	}

	&[type='number'] {
		-moz-appearance: textfield;
	}

	&:-webkit-autofill {
		color: #212121;
		font-size: 16px;
		background-color: #f4f;
		animation: onAutoFillStart 5000s linear;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		background-color: initial;
		-webkit-text-size-adjust: initial;
		-webkit-text-fill-color: initial;
		-webkit-box-shadow: 0 0 0 1000px #fff inset;
	}

	&::-webkit-credentials-auto-fill-button,
	&::-webkit-contacts-auto-fill-button {
		position: relative;
		top: 2px;
	}

	&:focus {
		outline: none;
	}

	&:disabled {
		color: rgba(#212121, 0.6);
	}

	@keyframes onAutoFillStart {
		// Workaround to force nesting this keyframe
		0% {
			position: relative;
		}
		100% {
			position: relative;
		}
	}

	@keyframes onAutoFillCancel {
		// Workaround to force nesting this keyframe
		0% {
			position: relative;
		}
		100% {
			position: relative;
		}
	}
}

.icon-container {
	position: absolute;
	width: 22px;
	height: 22px;
	right: 10px;
	top: 10px;
	cursor: pointer;
	fill: rgba(117, 117, 117, 0.54);
}
