@import '../../node_modules/normalize.css/normalize.css';
@import './variables.scss';
@import './mixins.scss';
@import './fonts.scss';

body {
  margin: 0;
	background: white;
	font-family: Lato, Open Sans, Roboto, Arial, 'sans-serif';
}

* {
	box-sizing: border-box;
}

html {
	--firm-orange: #ECC41B;
	--firm-dark: #3C3C51;
	--black: #2D2D2D;
	--white: #FFFFFF;
	--firm-brown: #CA882A;
	--light-orange: #F2D246;
	--firm-black: #3E4142;
	--light-black: #787A7B;
	--firm-grey: #A6A8BA;
	--light-grey: #CECFD8;
	--light-grey-warm: #F5F5F5;
}

a {
	text-decoration: none;
}
