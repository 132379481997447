.root {
	display: flex;
	align-items: center;
	opacity: 0;
	transition: 0.5s;
	visibility: hidden;
	transition-delay: 0s;

	&_visible {
		transition-delay: 0.1s;
		opacity: 1;
		visibility: inherit;
	}
}
