@font-face {
	font-family: 'Lato';
	src: url('../static/fonts/Lato-Semibold.woff2') format('woff2'),
	url('../static/fonts/Lato-Semibold.woff') format('woff'),
	url('../static/fonts/Lato-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Lato';
	src: url('../static/fonts/Lato-Bold.woff2') format('woff2'),
	url('../static/fonts/Lato-Bold.woff') format('woff'),
	url('../static/fonts/Lato-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Lato';
	src: url('../static/fonts/Lato-Light.woff2') format('woff2'),
	url('../static/fonts/Lato-Light.woff') format('woff'),
	url('../static/fonts/Lato-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Lato';
	src: url('../static/fonts/Lato-Regular.woff2') format('woff2'),
	url('../static/fonts/Lato-Regular.woff') format('woff'),
	url('../static/fonts/Lato-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}
