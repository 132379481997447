$maximum: 1200px;
$desktop: 920px;
$tablet: 768px;
$mobile: 540px;

$z-index-modal: 1000;

$color-blue: #008ac6;
$color-white: white;
$color-red: #ff4256;
$color-transparent: transparent;
