.logo {
	margin-top: auto;
	flex-shrink: 0;
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
}

.content {
	padding: 52px 86px 64px;
	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.15);
	background-color: #ffffff;
	max-width: 100%;
}

.title-stretcher {
	min-height: 52px;
	height: 102px;
}

.top-stretcher {
	min-height: 32px;
	height: 64px;
}

.bottom-stretcher {
	min-height: 64px;
	height: 142px;
}

.column {
	width: 460px;
	text-align: center;
}

.title {
	font-size: 24px;
	line-height: 36px;
	margin-top: 12px;
	font-weight: 600;
	text-align: center;
	padding-left: 12px;
	padding-right: 12px;
}

.login-title {
	font-weight: 600;
	font-size: 24px;
	height: 24px;
	margin-bottom: 70px;
}

.input {
	margin-bottom: 24px;
}

.button {
	margin-top: 120px;
	font-size: 16px;
	line-height: 20px;
	width: 100%;
	padding: 11px;
	text-transform: none;
	font-weight: 600;
}

.copyright {
	margin-top: auto;
	border-top: solid 1px #e8e8e8;
	padding-top: 32px;
	font-size: 12px;
	line-height: 16px;
	padding-bottom: 32px;
}
